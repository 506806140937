import { Container, Stack, Typography } from '@mui/material';
import { globalEspotContainerSX } from '@/components/content/EMarketingSpot/styles/globalEspotContainer';
import { FC } from 'react';

export const Copyright:FC <{isMobile?: boolean}> = ({isMobile}) => {
	const currentYear = new Date()?.getFullYear();
	return (
		<Container maxWidth='xl' sx={{ padding: { md: 0 } }}>
			<Stack component="div" alignItems={isMobile ? 'center': 'unset'} sx={globalEspotContainerSX}>
				<Typography variant='small'>{`Copyright ${currentYear} J. J. Keller & Associates, Inc.`}</Typography>
			</Stack>	
		</Container>
	);
};
